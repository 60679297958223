import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import Text from 'components/Text/Text';

export interface NotFoundProps {}

const defaultProps: Partial<NotFoundProps> = {};

const NotFound: React.FunctionComponent<NotFoundProps> = () => {
  const router = useRouter();

  useEffect(() => {
    console.info('404 - not found');
    router.replace('/');
  });

  return (
    <div
      style={{
        margin: '16px',
        height: '100svh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div>
        <Text size="heading1" text="404 Not Found" />
      </div>
      <div>
        <Text size="heading5" text="Redirecting home..." />
      </div>
    </div>
  );
};

NotFound.defaultProps = defaultProps;

export default NotFound;
